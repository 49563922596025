
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import GButton from '@/components/gsk-components/GskButton.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import EmptyState from '@/components/EmptyState.vue';
import { RouteNames } from '@/constants';
import HelpTooltip from '@/components/HelpTooltip.vue';
import ListingEntry from '@/components/Publish/ListingEntry.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import HelpLink from '@/components/HelpLink.vue';
import { TeamsModule } from '@/store/modules/teams.module';
import UserCircle from '@/components/UserCircle.vue';
import UserList from '@/components/UserList.vue';
import TeamListings from '@/components/teams/TeamListings.vue';
import { Team } from '@/types/teams.types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img from '@/assets/computer.svg';

@Component({
  components: {
    UserCircle,
    GButton,
    MaxWidth,
    EmptyState,
    HelpTooltip,
    ListingEntry,
    DeleteDialog,
    GDialog,
    HelpLink,
    UserList,
    TeamListings,
  },
})
export default class MyTeamsView extends Vue {
  private loading = true;
  private error = false;
  private deleteId = 0;
  private openDelete = false;
  public isAdminMode = false;
  public isFeatured = false;
  public listingId = -1;

  get headerStyle() {
    return {
      backgroundColor: 'var(--theme-lightest)',
    };
  }

  get imageSrc() {
    return img;
  }

  get teams() {
    return TeamsModule.teams;
  }

  get newTeamLink(): RawLocation {
    return { name: RouteNames.NewTeam };
  }

  delTeam(team: Team) {
    this.openDelete = true;
    this.deleteId = team.teamId;
  }

  async deleteTeam(): Promise<void> {
    await TeamsModule.deleteTeam(this.deleteId)
      .then(() => {
        this.openDelete = false;
        return this.loadTeams();
      })
      .catch(() => {
        this.openDelete = false;
      });
  }

  loadTeams() {
    return TeamsModule.getAllTeams()
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  createNewTeam() {}

  async created() {
    if (this.hasLoadedTeams) {
      this.loading = false;
      this.loadTeams();
    } else {
      await this.loadTeams();
    }
  }

  get hasLoadedTeams(): boolean {
    return TeamsModule.hasLoadedTeams;
  }
}
