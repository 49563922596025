
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { MyListing } from '@/types/publishing.types';
import GButton from '@/components/gsk-components/GskButton.vue';
import { Roles, RouteNames } from '@/constants';
import UserCircle from '@/components/UserCircle.vue';
import UserList from '@/components/UserList.vue';
import { Team } from '@/types/teams.types';
import { EnumsModule } from '@/store/modules/enums.module';

@Component({
  components: {
    GButton,
    UserCircle,
    UserList,
  },
})
export default class TeamListings extends Vue {
  @Prop(Object) readonly team!: Team;
  private open = false;

  get teamDetails(): RawLocation {
    return {
      name: RouteNames.TeamDetails,
      params: {
        teamId: this.team.teamId.toString(),
      },
    };
  }

  get userIsOwner(): boolean {
    const { roleId } = this.team.currentUserTeamInfo;
    return roleId === Roles.TeamOwner;
  }

  ownerRoleEnum(mnemonic: string): number {
    return EnumsModule.enums.role[mnemonic]?.id ?? -1;
  }

  get editLink(): RawLocation {
    return {
      name: RouteNames.EditTeam,
      params: {
        teamId: this.team.teamId.toString(),
      },
    };
  }

  @Emit('delete')
  del(team: Team) {
    return;
  }

  @Emit('add')
  add(listing: MyListing) {
    return;
  }
}
